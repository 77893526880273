import React, { useState, useEffect } from "react";
import LoadingIcon from "../UI/LoadingIcon";
import { TextField, Box, Typography, Grid, Switch } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ReceiptTypeContext } from "../../contexts/receiptTypeContext";
import { CurrencyContext } from "../../contexts/currencyContext";
import documentoElectronicoQrContext from "../../contexts/documentoElectronicoQrContext";
import DocumentsContext from "../../contexts/digitalDocumentsContexts";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import BasicButton from "../UI/BasicButton";
import BasicModal from "../UI/BasicModal";
import CancelModal from "./Modals/CancelModal";
import { setQrForm, setTolerancia } from "../../store/documents/documentsSlice";

// Extensiones para validar formulario
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";

const dJSON = require("dirty-json");

const useStyles = makeStyles((theme) => ({
  formBox: {
    marginBottom: "1em",
  },
  formInput: {
    marginBottom: ".75em",
    width: "100%",
  },
  formInputBig: {
    width: "100%",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  toggleContainer: {
    display: "flex",
    alignItems: "center",
    borderRadius: "4px",
    backgroundColor: "whitesmoke",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    padding: "8px",
  },
}));

let regDec = new RegExp(/^\d+(\.\d+)?$/);
let regNum = new RegExp(/^\d+$/);

const duplicateDocInitialStatus = {
  status: false,
  message: null,
};

export default function AfipForm() {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const informaCAI = JSON.parse(
    window.localStorage.getItem("provider")
  ).informaCAI;
  const version = window.localStorage.getItem("version");
  const cuitMastellone = parseInt(window.localStorage.getItem("cuit"));
  const selectedQr = useSelector((state) => state.documents.selectedQr);
  const userCe = JSON.parse(window.localStorage.getItem("user")).ingresa_Ce;
  const qrForm = useSelector((state) => state.documents.qrForm);
  const provider = JSON.parse(
    window.localStorage.getItem("provider")
  ).id_proveedor;
  const ingresaMonedaExtranjera = JSON.parse(
    window.localStorage.getItem("provider")
  ).ingresaMonedaExtranjera;
  const [duplicateDoc, setDuplicateDoc] = useState(duplicateDocInitialStatus);
  const handleCloseDuplicateDocModal = () =>
    setDuplicateDoc(duplicateDocInitialStatus);
  const handleCloseCancelModal = () => setCancelModal(false);
  const handleRedirectToContacto = () => {
    setDuplicateDoc(duplicateDocInitialStatus);
    history.push("/contact/create");
  };
  const [cancelModal, setCancelModal] = useState(false);

  const [receiptType, setReceiptType] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [foreign, setForeign] = useState(false);
  const [qrData, setQrData] = useState(null);
  const [comex, setComex] = useState(true);

  const handleSwitch = (e) => {
    setComex(e.target.checked);
  };

  const codAutTypes = [
    {
      id: 1,
      value: "N",
      label: "No informa",
    },
    {
      id: 2,
      value: "E",
      label: "CAE",
    },
    {
      id: 3,
      value: "A",
      label: "CAEA",
    },
    {
      id: 4,
      value: "I",
      label: "CAI",
    },
    {
      id: 5,
      value: "C",
      label: "CF",
    },
    {
      id: 6,
      value: "CE",
      label: "CESP",
    },
  ];

  const Monedas = [
    {
      id: 1,
      code: "PES",
      description: "Peso Argentino",
    },
    {
      id: 2,
      code: "DOL",
      description: "Dolar Estadounidense",
    },
    {
      id: 3,
      code: "003",
      description: "Franco Francés",
    },
    {
      id: 4,
      code: "012",
      description: "Real Brasilero",
    },
    {
      id: 5,
      code: "060",
      description: "Euro",
    },
  ];

  const minDate = moment().subtract(6, "months").format("YYYY-MM-DD");

  const formik = useFormik({
    initialValues: {
      cuit: "",
      tipoCodAut: "",
      codAut: "",
      fecha: "",
      tipoCmp: "",
      ptoVta: "",
      nroCmp: "",
      moneda: "",
      ctz: "",
      fechaCodAuth: "",
      importe: "",
      importeTotalPesos: "",
      importeTotalPesosNoTax: "",
      tipoDocRec: 80,
      nroDocRec: "",
      comercioExterior: true,
    },
    validationSchema: Yup.object({
      cuit: Yup.number()
        .typeError("El CUIT debe ser un numero")
        .test({
          name: "cuit",
          message: "El CUIT ingresado no coincide con el proveedor logueado",
          test: (value) =>
            parseInt(value) ===
            parseInt(JSON.parse(window.localStorage.getItem("provider")).cuit),
        })
        .required("El CUIT es obligatorio")
        .positive()
        .integer(),
      tipoCodAut: Yup.string().required(
        "El tipo de código de autorización es obligatorio"
      ),
      codAut: Yup.number().test({
        name: "codAut",
        message:
          "El codigo de autorización deberá tener exactamente 14 dígitos",
        test: (value) => {
          return !informaCAI || (value && value.toString().length === 14);
        },
      }),
      fecha: Yup.date()
        .typeError("La fecha debe ser una fecha válida")
        .test(
          "fecha-min",
          "La fecha no puede ser menor a 6 meses atrás",
          (value) => moment(value).isSameOrAfter(moment().subtract(6, "months"))
        )
        .required("La fecha es requerida"),
      tipoCmp: Yup.number().required("El tipo de campo es obligatorio"),
      ptoVta: Yup.string()
        .typeError("El punto de venta es obligatorio")
        .required("El punto de venta es obligatorio"),
      nroCmp: Yup.number()
        .typeError("El número de comprobante debe ser un numero")
        .required("El número de comprobante es obligatorio"),
      fechaCodAuth: Yup.date()
        .typeError("La fecha de autorización debe ser una fecha válida")
        .test("codAut", "Codigo de autorización invalido", (value) => {
          return !informaCAI || value;
        })
        .when("fecha", (fecha, schema) => {
          return schema.test({
            name: "fechaCodAuth-min",
            message:
              "La fecha de vencimiento del código de autorización no puede ser anterior a la fecha de emisión del comprobante",
            test: (fechaCodAuth) =>
              !fechaCodAuth ||
              moment(fechaCodAuth).isSameOrAfter(moment(fecha)),
          });
        }),
      importe: Yup.string()
        .matches(regDec, "La cotización debe ser un número")
        .typeError("El importe debe ser un numero")
        .required("El importe es obligatorio"),
      importeTotalPesos: Yup.string()
        .matches(regDec, {
          message: "El importe total sin impuestos ($ARS) debe ser un numero",
        })
        .test(
          "importeTotalPesos",
          "El importe total ($ARS) tiene que ser igual a la cotización por el importe total y mayor a cero.",
          function (value) {
            const { importe, ctz } = this.parent;
            const importeNum = parseFloat(importe);
            const ctzNum = parseFloat(ctz);
            const valueNum = parseFloat(value);

            if (isNaN(importeNum) || isNaN(ctzNum) || isNaN(valueNum)) {
              return false;
            }
            const tolerance = 0.01; // tolerancia de 1 centavo
            const calculatedValue = importeNum * ctzNum;
            return Math.abs(valueNum - calculatedValue) < tolerance && valueNum > 0;
          }
        )
        .required("El importe total obligatorio"),
      importeTotalPesosNoTax: Yup.string()
        .matches(regDec, {
          message: "El importe total sin impuestos ($ARS) debe ser un numero",
        })
        .test({
          name: "importeTotalPesos",
          message:
            "El importe total sin impuestos ($ARS) no puede ser mayor que el Importe Total ($ARS)",
          test: (value) =>
            parseFloat(value) <= parseFloat(formik?.values?.importeTotalPesos),
        })
        .required("El importe total sin impuestos es obligatorio"),
      nroDocRec: Yup.string().test({
        name: "nroDocRec",
        message: `El CUIT del receptor debe coincidir con el de ${window.localStorage.getItem(
          "abbreviation"
        )}`,
        test: (value) => {
          const isNumber = value !== "" && regNum.test(value);
          const isValidCompanyCuit = parseInt(value) === cuitMastellone;
          return foreign ? isNumber && isValidCompanyCuit : true;
        },
      }),
      moneda: Yup.string().required("La moneda es obligatoria"),
      ctz: Yup.string()
        .matches(regDec, "La cotización debe ser un número")
        .required("La cotización es obligatoria")
        .test({
          name: "ctz",
          message: "La cotización debe ser mayor a cero",
          test: (value) => parseFloat(value) > 0,
        }),
    }),
    onSubmit: async (values) => {
      const qrForm = {
        ...values,
        importe: parseFloat(values.importe).toFixed(2),
        importeTotalPesos: parseFloat(values.importeTotalPesos).toFixed(2),
        importeTotalPesosNoTax: parseFloat(
          values.importeTotalPesosNoTax
        ).toFixed(2),
        tipoCmp: parseInt(values.tipoCmp),
      };
      dispatch(setQrForm(qrForm));

      if (userCe) {
        const extcom = await DocumentsContext.setDocumentComex(
          selectedQr?.id_documento_electronico,
          comex
        );
      }
      const response = await documentoElectronicoQrContext.updateQr(
        JSON.stringify(qrForm),
        selectedQr?.id_documento_electronico
      );
      if (response.status) {
        const tipoCompInt = parseInt(values.tipoCmp);
        const comprobante = receiptType.find(
          (tipo) => tipo.value === tipoCompInt
        )?.comprobante;
        if (comprobante?.id === 1 || comprobante?.id === 2) {
          //enviar al camino 1 del stepper
          history.push(
            `/documents/document-validation-pie-impositivo?v=${version}`
          );
        }
        if (comprobante?.id === 3) {
          //enviar al camino 2 del stepper
          // dispatch(setTolerancia(comprobante?.tolerancia));
          history.push(
            `/documents/document-validation-nota-pedido?v=${version}`
          );
        }
      } else {
        setDuplicateDoc({
          status: true,
          message: response.message,
        });
      }
    },
  });

  useEffect(() => {
    //condicion para que al recargar la pagina y no tenga datos, te mande al principio
    if (Object.keys(selectedQr)?.length === 0) {
      const version = window.localStorage.getItem("version");
      history.push("/documents/upload?v=" + version);
      return;
    }
    const jsonQr = selectedQr?.qr ? dJSON.parse(selectedQr?.qr) : {};
    setQrData({
      ...jsonQr,
      ...qrForm,
      tipoCmp: parseInt(qrForm?.tipoCmp) || parseInt(jsonQr?.tipoCmp),
    });

    formik.initialValues = {
      ...jsonQr,
      ...qrForm,
      tipoCmp: parseInt(qrForm?.tipoCmp) || parseInt(jsonQr?.tipoCmp),
    };
  }, [selectedQr, qrForm]);

  const fetchData = async () => {
    const [receiptTypes, currencies] = await Promise.all([
      ReceiptTypeContext.GetAll(),
      CurrencyContext.fetchCurrencies(),
    ]);

    const types = receiptTypes?.map((s) => {
      return {
        value: parseInt(s.codigo_comprobante),
        label: s.descripcion,
        comprobante: s.comprobante,
      };
    });
    setReceiptType(types);

    const curr = currencies?.map((c) => {
      return { value: c.code, label: c.description, id: c.id };
    });
    setCurrency(curr);
    if (qrData) {
      formik.setFieldValue("cuit", qrData?.cuit);
      formik.setValues({
        ...qrData,
        tipoCmp: types?.some((x) => x.value === qrData.tipoCmp)
          ? parseInt(qrData?.tipoCmp)
          : 1, //API AFIP => <TipoComprobante>
          moneda: curr?.some((x) => x.value === qrData.moneda) && ingresaMonedaExtranjera ? qrData?.moneda : "PES",
        importeTotalPesos:
        //qrData.importe && qrData.ctz ? 
      //(qrData?.moneda === "PES" ? parseFloat((qrData?.importe * qrData?.ctz).toFixed(2)) : qrData?.importe * qrData?.ctz) : 0,
          qrData.importe && qrData.ctz ? qrData?.importe * qrData?.ctz : 0,
          ctz: ingresaMonedaExtranjera ? qrData?.ctz : 1,
          tipoDocRec: qrData?.tipoDocRec || 80,
        nroDocRec: qrData?.nroDocRec || cuitMastellone,
      });
      setForeign(![19, 20, 21].includes(qrData?.tipoCmp));
    } else {
      formik.setValues({
        ...formik.values,
        moneda: "PES",
        ctz: 1,
        tipoCmp: 1,
        tipoDocRec: 80,
        nroDocRec: cuitMastellone,
      });
      setForeign(true);
    }
    if (!informaCAI) {
      formik.setFieldValue("tipoCodAut", "N");
      formik.setFieldValue("codAut", "");
      formik.setFieldValue("fechaCodAuth", "");
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(qrData)]);

  const handleCancelModal = () => {
    setCancelModal(true);
  };

  const handleCancel = async () => {
    const qr = await documentoElectronicoQrContext.fetchDigDocsQrd(provider);
    const qrDoc = qr.filter(
      (item) => item.id_documento === selectedQr?.id_documento_electronico
    )[0].id;
    const response = await documentoElectronicoQrContext.delete(
      qrDoc,
      selectedQr?.id_documento_electronico
    );
    history.push(`/documents?v=${version}`);
  };

  //Actualizo el importe total en pesos cuando se modifica el importe o la cotización
  useEffect(() => {
    formik.setValues({
      ...formik.values,
      importeTotalPesos:
        formik?.values?.importe && formik?.values?.ctz
          ? parseFloat(formik.values.importe * formik.values.ctz).toFixed(2)
          : "0.00",
    });
  }, [formik?.values?.ctz, formik?.values?.importe]);

  return (
    <Box>
      <Grid container className={classes.titleContainer}>
        <Grid item>
          <Typography variant="h4">Datos del documento</Typography>
        </Grid>
        {userCe && (
          <Grid item className={classes.toggleContainer}>
            <Typography>Comercio Exterior</Typography>
            <Switch checked={comex} onChange={handleSwitch} size="small" />
          </Grid>
        )}
      </Grid>
      {!isLoading && (
        <Box
          display="flex"
          flexDirection="column"
          width={"100%"}
          mt={"25px"}
          className={classes.formBox}
        >
          <Box display={"flex"} style={{ gap: "1em" }}>
            <TextField
              id="cuit"
              name="cuit"
              label="Número de CUIT (sin guiones)"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values?.cuit}
              variant="outlined"
              className={classes.formInput}
              required
              error={formik?.touched?.cuit && Boolean(formik?.errors?.cuit)}
              helperText={formik.errors?.cuit}
            />
            <TextField
              id="tipoCmp"
              name="tipoCmp"
              label="Tipo de Comprobante"
              select
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={parseInt(formik?.values?.tipoCmp)}
              variant="outlined"
              className={classes.formInput}
              required
              InputLabelProps={{ shrink: true }}
              error={
                formik?.touched?.tipoCmp && Boolean(formik?.errors?.tipoCmp)
              }
              helperText={formik.errors?.tipoCmp}
              SelectProps={{
                native: true,
              }}
            >
              {receiptType?.map((option) => (
                <option key={option.value} value={parseInt(option.value)}>
                  {`${option.value} - ${option.label}`}
                </option>
              ))}
            </TextField>
            <TextField
              id="ptoVta"
              name="ptoVta"
              label="Punto de Venta"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values?.ptoVta}
              variant="outlined"
              className={classes.formInput}
              required
              error={formik?.touched?.ptoVta && Boolean(formik?.errors?.ptoVta)}
              helperText={formik.errors?.ptoVta}
            />
            <TextField
              id="nroCmp"
              name="nroCmp"
              label="Número de Comprobante"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values?.nroCmp}
              className={classes.formInput}
              variant="outlined"
              required
              error={formik?.touched?.nroCmp && Boolean(formik?.errors?.nroCmp)}
              helperText={formik.errors?.nroCmp}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            style={{ gap: "1em" }}
            className={classes.formInput}
          >
            <TextField
              id="fecha"
              name="fecha"
              label="Fecha de Emisión del Comprobante"
              type="date"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values?.fecha}
              variant="outlined"
              className={classes.formInput}
              required
              error={formik?.touched?.fecha && Boolean(formik?.errors?.fecha)}
              helperText={formik.errors?.fecha}
              InputLabelProps={{ shrink: true }}
              inputProps={{ min: minDate }}
            />
            <TextField
              id="importe"
              name="importe"
              label="Importe Total (decimales separados por punto)"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values?.importe}
              variant="outlined"
              className={classes.formInputBig}
              required
              error={
                formik?.touched?.importe && Boolean(formik?.errors?.importe)
              }
              helperText={formik.errors?.importe}
            />
            <TextField
              id="importeTotalPesos"
              name="importeTotalPesos"
              label="Importe total ($ARS)"
              variant="outlined"
              required
              value={formik?.values?.importeTotalPesos}
              className={classes.formInputBig}
              error={
                formik?.touched?.importeTotalPesos &&
                Boolean(formik?.errors?.importeTotalPesos)
              }
              helperText={formik.errors?.importeTotalPesos}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <TextField
              id="importeTotalPesosNoTax"
              name="importeTotalPesosNoTax"
              label="Importe total sin impuestos ($ARS)"
              variant="outlined"
              className={classes.formInputBig}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values?.importeTotalPesosNoTax}
              required
              error={
                formik?.touched?.importeTotalPesosNoTax &&
                Boolean(formik?.errors?.importeTotalPesosNoTax)
              }
              helperText={formik.errors?.importeTotalPesosNoTax}
              InputLabelProps={{ shrink: true }}
            />
          </Box>
          <Box
            display={"flex"}
            width={"100%"}
            style={{ gap: "1em" }}
            className={classes.formInput}
          >
            <TextField
              id="tipoCodAut"
              name="tipoCodAut"
              label="Tipo de Cod. Autorizacion"
              select
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values?.tipoCodAut}
              variant="outlined"
              className={classes.formInput}
              required={informaCAI}
              disabled={!informaCAI}
              InputLabelProps={{ shrink: true }}
              error={
                formik?.touched?.tipoCodAut &&
                Boolean(formik?.errors?.tipoCodAut)
              }
              helperText={formik.errors?.tipoCodAut}
              SelectProps={{
                native: true,
              }}
            >
              {codAutTypes?.map((option) => (
                <option key={option.id} value={option.value}>
                  {`${option.label}`}
                </option>
              ))}
            </TextField>
            <TextField
              id="codAut"
              name="codAut"
              label="Código de Autorización"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values?.codAut}
              variant="outlined"
              className={classes.formInput}
              disabled={!informaCAI}
              required={informaCAI}
              error={formik?.touched?.codAut && Boolean(formik?.errors?.codAut)}
              helperText={formik.errors?.codAut}
            />
            <TextField
              id="fechaCodAuth"
              name="fechaCodAuth"
              label="Fecha venc. Cod.Aut"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values?.fechaCodAuth}
              variant="outlined"
              className={classes.formInput}
              disabled={!informaCAI}
              required={informaCAI}
              type="date"
              error={
                formik?.touched?.fechaCodAuth &&
                Boolean(formik?.errors?.fechaCodAuth)
              }
              helperText={formik.errors?.fechaCodAuth}
              InputLabelProps={{ shrink: true }}
              inputProps={{ min: formik.values.fecha }}
            />
          </Box>
          <Box
            display={"flex"}
            width={"100%"}
            style={{ gap: "1em" }}
            className={classes.formInput}
          >
            {foreign && (
              <>
                <TextField
                  id="tipoDocRec"
                  name="tipoDocRec"
                  label="Tipo documento Receptor"
                  variant="outlined"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={80}
                  className={classes.formInput}
                  required
                  error={
                    formik?.touched?.tipoDocRec &&
                    Boolean(formik?.errors?.tipoDocRec)
                  }
                  helperText={formik.errors?.tipoDocRec}
                  disabled
                />
                <TextField
                  id="nroDocRec"
                  name="nroDocRec"
                  label="Número documento Receptor"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.nroDocRec}
                  className={classes.formInput}
                  variant="outlined"
                  required
                  error={
                    formik?.touched?.nroDocRec &&
                    Boolean(formik?.errors?.nroDocRec)
                  }
                  helperText={formik.errors?.nroDocRec}
                />
              </>
            )}
            <TextField
              id="moneda"
              name="moneda"
              label="Moneda"
              select
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values?.moneda}
              variant="outlined"
              className={classes.formInput}
              required
              disabled={!ingresaMonedaExtranjera}
              InputLabelProps={{ shrink: true }}
              error={
                formik?.touched?.tipoCmp && Boolean(formik?.errors?.tipoCmp)
              }
              helperText={formik.errors?.tipoCmp}
              SelectProps={{
                native: true,
              }}
            >
              {currency?.map((option) => (
                <option key={option.value} value={option.value}>
                  {`${option.value} - ${option.label}`}
                </option>
              ))}
            </TextField>
            <TextField
              id="ctz"
              name="ctz"
              label="Cotización"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values?.ctz}
              variant="outlined"
              className={classes.formInput}
              disabled={!ingresaMonedaExtranjera}
              required
              error={formik?.touched?.ctz && Boolean(formik?.errors?.ctz)}
              helperText={formik.errors?.ctz}
            />
          </Box>
        </Box>
      )}
      {isLoading && <LoadingIcon />}
      <Box display={"flex"} style={{ gap: "10px" }}>
        <BasicButton
          variant="contained"
          disabled={!formik.isValid}
          onClick={formik.handleSubmit}
          color="primary"
        >
          Continuar
        </BasicButton>
        <BasicButton
          variant="contained"
          color="red"
          onClick={() => handleCancelModal()}
        >
          Cancelar
        </BasicButton>
      </Box>

      {/* Modals */}
      <BasicModal
        open={duplicateDoc?.status}
        onClose={handleCloseDuplicateDocModal}
        width="80%"
      >
        <Box
          display="flex"
          style={{ gap: 30 }}
          justifyContent="center"
          flexDirection="column"
        >
          <Typography variant="h5" marginBottom={"6px"}>
            {duplicateDoc?.message}
          </Typography>
          <Box display="flex" justifyContent="center" style={{ gap: "2rem" }}>
            <BasicButton onClick={handleCloseDuplicateDocModal}>
              Cerrar y revisar
            </BasicButton>
            <BasicButton
              variant="contained"
              onClick={handleRedirectToContacto}
              color="warning"
            >
              Generar Contacto
            </BasicButton>
          </Box>
        </Box>
      </BasicModal>

      <CancelModal
        open={cancelModal}
        onClose={handleCloseCancelModal}
        cancelConfirmation={handleCancel}
      />
    </Box>
  );
}
